<template>
  <div class="div-clas mb-5" style="margin-top:-5rem !important;">
      <div class="bg-chatbot img-fluid">
        <div class="container-fluid margin-text-titulo-chatbot">
          <div class="row" style="margin-top:3%;">
            <img class="img-header-25k-movil " style="margin-top:8rem !important;" src="../assets/Nosotros/icono_principal.png" alt="">
            <div class="col-md-5 offset-md-1">
              <h1 class="text-titulo-header-chatbot animate__animated  animate__bounceInDown animate__fast" data-aos="fade-up"><b>CHATBOT DE VENTAS EN WHATSAPP</b></h1>
              <div class="tamaño-div-chatbot"><p class="text-header-chatbot" data-aos="fade-up">Es una asistencia digital con inteligencoa artificival que ayudaraa hacer la ventas y atencion a clientes a tu empresa. Whatsapp es la app de mensajeria instantanea mas utilizada a nivel mundial; nosotros la usamos a tu favor. </p></div>
              <p class="text-chatbot" data-aos="fade-up">*Servicio no disponible en modalidad individual. Se trabaja en cualquiera de nuestros paquetes. </p>
            
            </div>
            <div class="col-md-6">
              <img class="img-header-25k " src="../assets/Nosotros/icono_principal.png" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row">
          <img class=" img-whatsapp-movil" src="../assets/Servicios/Chatbot_ventas_WA/whatsapp.png" alt="">
          <div class="col-md-5 offset-md-1 text-align-l-chatbot">
              <h1 class="text-titulo-chatbot" data-aos="fade-up"><b>Vende a todas horas</b></h1>
              <p class="p-chatbot mt-4" data-aos="fade-up">nuestro chatbot de whatsapp te ayudará a no perder ventas al momento en que te contacten tus prospectos. Está comprobado que contestar en el primer minuto aumenta la probabilidad de venta en más del 300%. No dejes que se te escapen los clientes por no contestar a tiempo.</p>
          </div>
          <div class="col-md-4 offset-md-1" data-aos="fade-up">
            <div class=""><img class="img-fluid img-whatsapp" data-aos="fade-up" src="../assets/Servicios/Chatbot_ventas_WA/whatsapp.png" alt=""></div>
          </div>
        </div>
      </div>
      <div class="container-fluid mt-3 " data-aos="fade-up">
        <div class="row">
          <div class="col-md-4 offset-md-1">
            <img class="img-fluid img-venta-movil " data-aos="fade-up" src="../assets/Servicios/Chatbot_ventas_WA/incrementa_ventas.png" alt="">
            <div class=""><img class="img-fluid img-venta" data-aos="fade-up" src="../assets/Servicios/Chatbot_ventas_WA/incrementa_ventas.png" alt=""></div>
          </div>
          <div class="col-md-5 offset-md-1 text-align-l-chatbot" data-aos="fade-up">
              <h1 class="text-titulo-chatbot"><b>Incrementa tus ventas</b></h1>
              <p class="p-chatbot mt-4">Aumenta tus probabilidades de venta mediante retargeting de carritos en Whatsapp; cada vez que alguien deje un producto en el carrito de tu ecommerce, este se lo enviará mediante whatsapp con un incentivo de compra (código de descuento) o le enviará un producto relacionado</p>
          </div>
        </div>
      </div>
    <p class="mb-5 mt-5 text-chatbot2" data-aos="fade-up" ><b> *Servicio no disponible en modalidad individual. Se trabaja en cualquiera de nuestros paquetes. </b></p> <br>
    <div>
        <a class="btn btn-outline-primary btn-lg pill mt-4 shadow"  href="mailto:ventas@techsoftconsulting.mx">
        <span>
          <span class="h6 mr-2 font-weight-bold">Contactenos</span>
          <b-icon icon="arrow-right" aria-hidden="true"></b-icon>
        </span>
      </a>
      </div>
  </div> 
</template>

<script>
export default {

}
</script>

<style>
.pill {
  
  
  padding: 10px 20px;
  text-align: center;
  
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 30px;
}
.img-venta{
  display: inline;
  margin-top: 5%;
}
.img-venta-movil{
   display: none;
}
.img-whatsapp{
  display: inline;
}
.img-whatsapp-movil{
  display: none;
}
.img-header-25k-movil{
  display: none;
}
.img-header-25k{
  display: inline;
}
.f-chatbot{
  height: 500px;
  margin-bottom: 20%;
  margin-top: -1%;
}

.bg-chatbot { 
  /* The image used */
  background-image: url("../assets/Inicio/principal.png");
  margin-top: 5%;
  height: 130%; 


  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.div1-25k{
  background-image: url("../assets/Inicio/creacion_pagina_web.png");
   width: 100%;
  height: 100%;

}
.div2-25k{
  background-image: url("../assets/Inicio/desarrollo_plataformas.png");
  width: 100%;
  height: 100%;


}
.div3-25k{
  background-image: url("../assets/The_25k_Club/sitio_web.png");
  width: 100%;
  height: 100%;


}
.div4-25k{
  background-image: url("../assets/The_25k_Club/redes_sociales.png");
  width: 100%;
  height: 100%;


}
.div5-25k{
  background-image: url("../assets/Inicio/campanas_fb_ga.png");
  width: 100%;
  height: 100%;


}
.div6-25k{
  background-image: url("../assets/The_25k_Club/sms_masivos.png");
  width: 100%;
  height: 100%;


}


@-webkit-keyframes mover2 {
    1% { transform: translateX(1px); }
    50% { transform: translateY(-15px); }
}

@media only screen and (max-width: 770px) {
  .p-chatbot{
    font-size: 1.2rem !important;
    width: 100%;
  }
  .text-titulo-chatbot{
  font-size: 1.6rem !important;
    text-align: center;
}
  
  .text-header-chatbot{
    color: rgb(255, 255, 255);
    font-size: 20px !important;
    text-align: justify;
    padding: 5%;
    margin-top: -5%;
    
    
  }
  .img-header-25k{
  display: none;
}
  .img-header-25k-movil{
  display: inline;
    width: 40%;
    margin-top: 15%;
    margin-bottom: 10%;
    margin-left: 30%;}
.fondo-web{
  display: none;
}
.fondo-movil{
  display: inline;
  margin-right: 0%;
}
.text-titulo-header-chatbot{
    color: rgb(255, 255, 255);
    font-size: 40px !important;
    width: 80%;
    margin-left: 10%;
    text-align: center;
  }
  .bg-chatbot { 
    height: 155%;
  }
  .text-chatbot{
  font-size: 0.8rem !important;
  margin-top: 0px;
  margin-bottom: 50;
  margin-left: 5%;
}
.img-whatsapp-movil{
  display: inline;
    width: 100%;
    margin-top: 5%;
    margin-bottom: 5%; 
    margin-left: 0%;
}
.img-whatsapp{
  display: none;
}
.img-venta{
  display: none;
}
.img-venta-movil{
  display: inline;
    width: 100%;
    margin-top: 5%;
    margin-bottom: 5%; 
    margin-left: 0%;
}
.text-chatbot2{
  font-size: 0.8rem !important;
  margin-top: 0px;
  margin-bottom: 50;
  margin-right: 5%;
  margin-left: 5%;
}
}
@media only screen and (max-width: 1070px) and (min-width: 720px) {
  .text-titulo-header-chatbot, .img-header-25k{
    margin-top: 3rem !important;
    
  }
  
}

</style>